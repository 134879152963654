//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//


import LIB_HOME from "../../library/libHome";

export default {
  data() {
    return {

      form : {
        id : '',
        pk_unit_sasaran : '',
        pk_unit_sasaran_indikator : '',

        ku_ra_strategis_indikator : '',
        ku_ra_strategis_indikator_capaian : '',
        pk_unit_sasaran_indikator_capaian : '',
        hari : '',
        bulan : '',
        tahun : '',
        worksheet_uraian : '',
        worksheet_capaian : '',
        worksheet_satuan : '',
        keterangan : '',
      },


      filterku : {
        sub_unit_kerja : '',
        delegasi : '',
      },
     
      list_data : [],
      list_sasaran : [],
      list_sasaran_indikaor : [],
      list_ra_indikaor : [],

      page_first: 1,
      page_last: 0,
      cari_value: "",
      cek_load_data : true,


      mdl_add: false,
      mdl_edit: false,
      mdl_hapus : false,
      btn_add: false,

      LIB_HOME : LIB_HOME,
    }
  },
  methods: {


    getView : function(){
      this.$store.commit("shoWLoading");
      fetch(this.$store.state.url.URL_MasterKategori + "view", {
          method: "POST",
          headers: {
          "content-type": "application/json",
          authorization: "kikensbatara " + localStorage.token
          },
          body: JSON.stringify({
              data_ke: this.page_first,
              cari_value: this.cari_value
          })
      })
          .then(res => res.json())
          .then(res_data => {
              this.list_data = res_data.data;
              this.page_last = res_data.jml_data;
              this.$store.commit("hideLoading");
              console.log(res_data);
      });
    },


    addData : function(number) {
      fetch(this.$store.state.url.URL_MasterKategori + "Add", {
          method: "POST",
          headers: {
            "content-type": "application/json",
            authorization: "kikensbatara " + localStorage.token
          },
          body: JSON.stringify(this.form)
      }).then(res_data => {
          this.Notify('Sukses Menambah Data', 'primary', 'check_circle_outline');
          this.getView();
      });
    },


    editData : function(){
      fetch(this.$store.state.url.URL_MasterKategori + "editData", {
          method: "POST",
          headers: {
            "content-type": "application/json",
            authorization: "kikensbatara " + localStorage.token
          },
          body: JSON.stringify(this.form)
      }).then(res_data => {
          this.Notify('Sukses Merubah Data', 'warning', 'check_circle_outline');
          this.getView();
      });
    },

    removeData : function(E){
      fetch(this.$store.state.url.URL_MasterKategori + "removeData", {
          method: "POST",
          headers: {
            "content-type": "application/json",
            authorization: "kikensbatara " + localStorage.token
          },
          body: JSON.stringify({id : this.form.id})
      }).then(res_data => {
          this.Notify('Sukses Menghapus Data', 'negative', 'check_circle_outline');
          this.getView();
      });

    },
    selectData : function(data){
        this.form.id = data.id;
        this.form.uraian = data.uraian;
        this.form.keterangan = data.keterangan;
    },

    getAsycSasaran : async function(){
      this.list_sasaran = await LIB_HOME.getSasaran(this.filterku.delegasi)
      console.log(this.list_sasaran[0].id);
    },
    getAsycSasaranInikator : async function(){
      this.list_sasaran = await LIB_HOME.getSasaran(this.filterku.delegasi)
    },
    getAsycRAIndikator : async function(){
      this.list_sasaran = await LIB_HOME.getSasaran(this.filterku.delegasi);
      console.log(this.list_sasaran);
    },


    getAsyc : async function(){
      await this.getAsycSasaran()
      this.form.pk_unit_sasaran = this.list_sasaran[0].id

      console.log(this.list_sasaran[0].id)
    },

    // ====================================== PAGINATE ====================================
        Notify : function(message, positive, icon){
          this.$q.notify({
            message: message,
            color: positive,
            icon: icon,
            position : 'top',
            timeout: 500,
          })
        },
        btn_prev : function(){
            this.cek_load_data = true;
            if(this.page_first>1){
                this.page_first--
            }else{
                this.page_first = 1;
            }
            this.getView();
        },

        btn_next : function(){
            if(this.page_first >= this.page_last){
                this.page_first == this.page_last
            }else{
                this.page_first++;
            }
            this.getView();
        },

        cari_data : function(){
            this.page_first = 1;
            this.getView();
        },


    // ====================================== PAGINATE ====================================







  },

  mounted () {
    const d = new Date();
    this.form.tahun = d.getFullYear();


    var get_profile = JSON.parse(localStorage.profile);
    this.filterku.sub_unit_kerja = get_profile.profile.unit_kerja;
    this.filterku.delegasi = get_profile.profile.jabatan;


    this.getAsyc();
    

    // this.getView();
  },
}
