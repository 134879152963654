var DataStore = require('../store');
var store = DataStore.default


const barChart = ()=>{
    
}


const getSasaran = async (delegasi)=>{

    console.log(store.state.url.URL_kinerja_pk_unit_sasaran)
    return new Promise((resolve)=>{

        fetch(store.state.url.URL_kinerja_pk_unit_sasaran + "list", {
            method: "POST",
            headers: {
                "content-type": "application/json",
                authorization: "kikensbatara " + localStorage.token
            },
            body: JSON.stringify({
                delegasi: delegasi,
            })
          })
            .then(res => res.json())
            .then(res_data => {
            //   console.log(res_data)
              resolve(res_data)
    
        });



    })
}



const getndikatorSasaran = async (delegasi)=>{
    return new Promise((result)=>{

        fetch(store.state.url.URL_kinerja_pk_unit_sasaran + "list", {
            method: "POST",
            headers: {
                "content-type": "application/json",
                authorization: "kikensbatara " + localStorage.token
            },
            body: JSON.stringify({
                delegasi: delegasi,
            })
          })
            .then(res => res.json())
            .then(res_data => {
              // console.log(res_data)
              resolve(res_data)
    
        });



    })
}



const getndikatorRA = async (delegasi)=>{
    return new Promise((result)=>{

        fetch(store.state.url.URL_kinerja_pk_unit_sasaran + "list", {
            method: "POST",
            headers: {
                "content-type": "application/json",
                authorization: "kikensbatara " + localStorage.token
            },
            body: JSON.stringify({
                delegasi: delegasi,
            })
          })
            .then(res => res.json())
            .then(res_data => {
              // console.log(res_data)
              resolve(res_data)
    
        });



    })
}
















module.exports = {
    barChart : barChart,
    getSasaran : getSasaran,
    getndikatorSasaran : getndikatorSasaran,
    getndikatorRA : getndikatorRA,

}